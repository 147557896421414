'use client';
import { useAtomValue } from 'jotai';
import { useRouter } from 'next/navigation';

import { ChevronLeft } from '@gds/Icons/Paths/ChevronLeft';

import { Typography } from '@gds/Typography/Typography';

import {
  isMockBackButtonEnabledAtom,
  isMockHeaderVisibleAtom,
  mockHeaderTitleAtom,
} from '../../Atoms/MockNativeUiAtoms';

import styles from './MockNativeHeader.module.css';

export const MockNativeHeader = () => {
  const { back } = useRouter();
  const isVisible = useAtomValue(isMockHeaderVisibleAtom);
  const title = useAtomValue(mockHeaderTitleAtom);
  const isMockBackButtonEnabled = useAtomValue(isMockBackButtonEnabledAtom);

  if (!isVisible) return null;

  return (
    <div className={styles.wrapper}>
      <Typography variant="h6" className={styles.title}>
        {title}
      </Typography>
      {isMockBackButtonEnabled && (
        <button className={styles.iconButton} onClick={back}>
          <ChevronLeft size={24} />
        </button>
      )}
    </div>
  );
};

MockNativeHeader.displayName = 'MockNativeHeader';
