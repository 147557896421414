'use client';

import { useRef, useEffect } from 'react';

import { usePathname, useSearchParams } from 'next/navigation';

import styles from './MockNativeWrapper.module.css';
type Props = {
  children: React.ReactNode;
};

export const MockNativeContent = ({ children }: Props) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    contentRef.current?.scrollTo?.(0, 0);
  }, [pathname, searchParams]);

  return (
    <div className={styles.content} ref={contentRef}>
      {children}
    </div>
  );
};

MockNativeContent.displayName = 'MockNativeContent';
