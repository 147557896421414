'use client';

import Link from 'next/link';
import { useRouter } from 'next/navigation';

import { Car } from '@gds/Icons/Paths/Car';
import { Dealer } from '@gds/Icons/Paths/Dealer';
import { HeartDefault } from '@gds/Icons/Paths/HeartDefault';
import { Search } from '@gds/Icons/Paths/Search';
import { Sort } from '@gds/Icons/Paths/Sort';

import styles from './MockNativeMenu.module.css';

export const MockNativeMenu = () => {
  const { push: _push } = useRouter();

  return (
    <div className={styles.wrapper}>
      <Link href="/" legacyBehavior>
        <button className={styles.iconButton}>
          <Search size={24} />
          Search
        </button>
      </Link>
      <Link href="/leasing" prefetch={false} legacyBehavior>
        <button className={styles.iconButton}>
          <Car size={24} />
          Leasing
        </button>
      </Link>
      <Link href="/favourites" prefetch={false} legacyBehavior>
        <button className={styles.iconButton}>
          <HeartDefault size={24} />
          Favourites
        </button>
      </Link>
      <Link href="/value-my-car" prefetch={false} legacyBehavior>
        <button className={styles.iconButton}>
          <Sort size={24} />
          Sell
        </button>
      </Link>
      <Link href="/about" prefetch={false} legacyBehavior>
        <button className={styles.iconButton}>
          <Dealer size={24} />
          About us
        </button>
      </Link>
      {/* <Link href="/accounts" prefetch={false} legacyBehavior>
        <button className={styles.iconButton}>
          <Dealer size={24} />
          My heycar
        </button>
      </Link> */}
    </div>
  );
};

MockNativeMenu.displayName = 'MockNativeHeader';
